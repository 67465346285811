.App {
  background-color: #96f1a5;
}
* {background-color: black};
.AppBackground{
  background-color: #888787;
}
.AppMargins {
  background-color: #96f1a5;
  margin-left: 5%;
  margin-right: 5%;
}
.AppMarginsText {
  background-color: #888787;
  margin-left: 5%;
  margin-right: 5%;
}

.WhiteSpace{
  background-color: #fdfdfd;
  color: #fdfdfd;
}
.App-header {
  background-color: #96f1a5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family:'Times New Roman', Times, serif;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  margin-left: 5%;
  margin-right: 5%;
}

.App-Chapter-Title {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px);
  color: rgb(5, 0, 0);
  margin-left: 5%;
  margin-right: 5%;
  font-family: 'Times New Roman', Times, serif;
}
.App-Body-Text-Wrapper {
  background-color: #fdfdfd;
  margin-left: 5%;
  margin-right: 5%;
  
}
.App-Body-Text-Paragraph {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  font-size: calc(20px);
  color: rgb(5, 0, 0);
  margin-left: 7%;
  margin-right: 7%;
  text-indent: 50px;
}
.App-Body-Text-Paragraph1 {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
  align-items: right; 
  justify-content: left;
  font-size: calc(20px);
  color: rgb(5, 0, 0);
  margin-left: 7%;
  margin-right: 7%;
}
.App-Body-Text {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  font-size: calc(20px);
  color: rgb(5, 0, 0);
  margin-left: 5%;
  margin-right: 5%;
}
.SmallTitle {
  font-size: calc(40px);
}
.Author {
  font-size: calc(40px);
  margin-top: 10%;
}
.App-Body-Text-Bold {
  background-color: #fdfdfd;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  font-size: calc(20px);
  color: rgb(5, 0, 0);
  margin-left: 5%;
  margin-right: 5%;
}
